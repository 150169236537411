import constants from "constants/constants";
import {
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__RESET_STATE,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__EDIT_REVIEW,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DUPLICATE_REVIEW,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DELETE_REVIEW,
} from "./actions";

const initialState = {
  published_reviews: {
    review_payload: {
      uuid: "",
      listing_uuid: null,
      image: null,
      name: null,
      sub_heading: null,
      description: null,
      rating: 0,
      status: null,
    },
    show_offerings: true,
    error_object: {},
    show_modal: false,
    show_delete_modal: false,
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE: {
      return {
        ...state,
        published_reviews: {
          ...state.published_reviews,
          [payload.key]: payload.value,
        },
      };
    }

    case EXLY_FEEDBACKS__PUBLISHED_REVIEWS__RESET_STATE: {
      return {
        ...state,
        published_reviews: initialState.published_reviews,
      };
    }

    case EXLY_FEEDBACKS__PUBLISHED_REVIEWS__EDIT_REVIEW: {
      let temp = {};
      Object.keys(initialState.published_reviews.review_payload).forEach(
        (key) => (temp[key] = payload[key])
      );
      temp.name = payload.customer_name;
      return {
        ...state,
        published_reviews: {
          ...state.published_reviews,
          review_payload: temp,
          show_offerings: false,
          show_modal: true,
        },
      };
    }

    case EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DUPLICATE_REVIEW: {
      let temp = {};
      Object.keys(initialState.published_reviews.review_payload).forEach(
        (key) => (temp[key] = payload[key])
      );
      temp.name = payload.customer_name;
      temp.status = constants.listing_review_status.draft;
      delete temp.uuid;
      return {
        ...state,
        published_reviews: {
          ...state.published_reviews,
          review_payload: temp,
          show_offerings: false,
          show_modal: true,
        },
      };
    }

    case EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DELETE_REVIEW: {
      return {
        ...state,
        published_reviews: {
          ...state.published_reviews,
          review_payload: {
            ...state.published_reviews.review_payload,
            uuid: payload,
          },
          show_delete_modal: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
