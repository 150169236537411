import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { matchPath } from "react-router";
import { leaderboard_api } from "./Leaderboard.api";

const leaderboard_resource_apis = {
  [app_route_ids[app_route_keys.leaderboard]]: ({ pathname }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.leaderboard],
    });
    return {
      api_end_point: leaderboard_api.get_customers,
      queries: {
        listing_uid: matchResults?.params?.listing_uuid,
      },
    };
  },
};

export default leaderboard_resource_apis;
