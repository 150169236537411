import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { matchPath } from "react-router";
import { manage_branded_community_apis } from "../../../constants/ManageBrandedCommunity.apis";

const members_resource_apis = {
  [app_route_ids[app_route_keys.manage_branded_community_members]]: ({
    pathname,
  }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.manage_branded_community_home],
    });
    return {
      api_end_point: manage_branded_community_apis.get_community_members,
      queries: {
        community_listing_id: matchResults?.params?.branded_community_uuid,
      },
    };
  },
};

export default members_resource_apis;
