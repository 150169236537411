export const FETCH_FEATURE_CREDITS_API = "business/credits/details";
export const FETCH_FEATURE_LIST_API = "business/credits/feature/consumables";
export const FETCH_CREATOR_ACTIVE_PLAN_API = "business/combo/details";
export const FETCH_SUBSCRIPTION_TRANSACTION_API = "business/combo/plans";
export const UPDATE_CART_API = "business/credits/cart/update";
export const FETCH_CART_API = "business/credits/cart/items";
export const INITIATE_PAYMENT_API = "business/credits/payment/initiate";
export const FETCH_ORDER_STATUS_API = "business/credits/payment/status";
export const FETCH_FEATURE_LEDGER_API = "business/credits/ledger";
export const FETCH_LEDGER_TRIGGERS_API = "business/credits/ledger/trigger";
export const FETCH_HIGHLIGHTS_API = "business/credits/highlight";
