const email_drip_api = {
  create_drip: "notifications/create/drip",
  get_email_drip_list: "notifications/list/drip",
  get_email_drip_details: "notifications/drip/details",
  update_drip_audience_details: "notifications/update/drip",
  get_drip_email_list: "notifications/email/drip/list",
  create_email_in_drip: "notifications/create/drip/email",
  update_email_in_drip: "notifications/update/drip/email",
  delete_email_in_drip: "notifications/delete/drip/email",
  launch_email_drip: "notifications/launch/drip",
  pause_email_drip: "notifications/pause/drip",
  delete_emai_drip: "notifications/delete/drip",
  duplicate_email_drip: "notifications/duplicate/drip",
  resume_email_drip: "notifications/resume/drip",
  end_email_drip: "notifications/end/drip",
  get_drip_email_details: "notifications/email/drip/details",
  reset_drip_schedules: "notifications/reset/drip",
  // drip triggers
  get_notification_trigger_details: "notifications/drip/trigger/details",
  get_drip_triggers: "notifications/drip/details",
  create_drip_trigger: "notifications/create/drip/triggers",
  update_drip_trigger: "notifications/update/drip/triggers",
  delete_drip_trigger: "notifications/delete/drip/triggers",
  // conditions
  create_condition: "notifications/create/campaign/automation",
  update_condition: "notifications/update/campaign/automation",
  delete_condition: "notifications/delete/campaign/automation",
};

export default email_drip_api;
