import api from 'data/APIs';
import dataProvider from 'data/dataProvider';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { is_empty } from 'utils/validations';
import {
    EXLY_PARTNERSHIP__GET_BRAND_PARTNERSHIPS,
    EXLY_PARTNERSHIP__SET_BRAND_PARTNERSHIPS,
    EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER

} from './actions';

function* getBrandPartnerships(data) {
    try {
        const partnerships = yield select(state => state.partnership.brand_data);
        if (is_empty(partnerships) || data.force_fetch) {
            yield put({ type: EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER, payload: true });
            const response = yield call(dataProvider.custom_request,  api.get_brand_partnerships,"GET");
            if (response.status === 200) {
                yield put({ type:  EXLY_PARTNERSHIP__SET_BRAND_PARTNERSHIPS, payload: response.data.brand_data });
            } else {
                throw `API: ${api.get_brand_partnerships} FAIL`;
            }
        }
        else {
            yield put({ type:  EXLY_PARTNERSHIP__SET_BRAND_PARTNERSHIPS, payload: partnerships });
        }
    } catch (error) {
        console.log('error', error);
        yield put({ type:  EXLY_PARTNERSHIP__SET_BRAND_PARTNERSHIPS, payload: null });
    } finally {
        yield put({ type: EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER, payload: false });
        console.log("Done")
    }
}

// ? COMMENTING OUT THE CODE. WE MIGHT NEED IT LATER
// function* getAffiliatePartnerships(data) {
//     try {
//         const partnerships = yield select(state => state.partnership.brand_data);
            // yield put({ type: EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER, payload: true });

//         if (is_empty(partnerships) || data.force_fetch) {
//             const response = yield call(dataProvider.custom_request,  api.get_brand_partnerships,"GET");
//             if (response.status === 200) {
//                 yield put({ type:  EXLY_PARTNERSHIP__SET_AFFILIATE_PARTNERSHIPS, payload: response.data.brand_data });
//             } else {
//                 throw `API: ${api.get_brand_partnerships} FAIL`;
//             }
//         }
//         else {
//             yield put({ type:  EXLY_PARTNERSHIP__SET_AFFILIATE_PARTNERSHIPS, payload: partnerships });
//         }
//     } catch (error) {
//         console.log('error', error);
//         yield put({ type:  EXLY_PARTNERSHIP__SET_AFFILIATE_PARTNERSHIPS, payload: null });
//     } finally {
//         console.log("Done")
            // yield put({ type: EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER, payload: false });

//     }
// }

function* partnershipSaga() {
    yield takeEvery(EXLY_PARTNERSHIP__GET_BRAND_PARTNERSHIPS, getBrandPartnerships);
    // yield takeEvery(EXLY_PARTNERSHIP__GET_AFFILIATE_PARTNERSHIPS, getAffiliatePartnerships);

}

export default partnershipSaga;
