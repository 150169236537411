import {
  EXLY_PARTNERSHIP__SET_BRAND_PARTNERSHIPS,
  EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER
} from "./actions";

const initialState = {
  brand_data: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case EXLY_PARTNERSHIP__SET_BRAND_PARTNERSHIPS: {
      return { ...state, brand_data: payload, processing: false };
  }
  
  case EXLY_PARTNERSHIP__PARTNERSHIP_SET_LOADER: {
    return { ...state, loader: payload };
}

//   case EXLY_PARTNERSHIP__SET_AFFILIATE_PARTNERSHIPS: {
//     return { ...state, affiliate_data: payload, processing: false };
// }
    default: {
      return state;
    }
  }
};

export default reducer;
