import { app_route_ids, app_route_keys } from "constants/urlPaths";
import {
  FETCH_FEATURE_LEDGER_API,
  FETCH_FEATURE_LIST_API,
  FETCH_SUBSCRIPTION_TRANSACTION_API,
} from "./account.apis";

export const dummyResponse = {
  total: 1,
  total_pages: 1,
  data: [
    {
      name: "Whatsapp Marketing",
      free_credits: {
        alloted: 1000,
        consumed: 1000,
      },
      paid_credits: {
        alloted: 1000,
        consumed: 1000,
      },
      credit_purchase_plan: {
        price: 100,
        units: 100,
        unit_name: "Messages",
      },
    },
  ],
};

export const account_resource_apis = {
  [app_route_ids[app_route_keys.my_subscriptions]]: {
    api_end_point: FETCH_FEATURE_LIST_API,
  },
  [app_route_ids[app_route_keys.subscription_transactions]]: {
    api_end_point: FETCH_SUBSCRIPTION_TRANSACTION_API,
  },
  [app_route_ids[app_route_keys.ledger]]: ({ pathname }) => {
    const tempArray = pathname.split("/");
    const feature_key = tempArray[tempArray.length - 1];

    return {
      api_end_point: FETCH_FEATURE_LEDGER_API,
      queries: { feature_key },
    };
  },
};

export const default_credits_color = "rgba(73, 58, 177, 1)";
export const expired_credits_color = "rgba(191, 38, 0, 1)";
export const null_allotted_color = expired_credits_color;
export const warning_credits_color = "rgba(255, 170, 0, 1)";
export const progress_background_color = "rgba(227, 226, 238, 1)";

export const default_credits_warning_percentage = 90;
export const default_credits_expired_percentage = 100;

export const warning_plan_days_to_expire = 10;
