import constants from "constants/constants";
import { roundOff } from "utils/Utils";
import { is_empty } from "utils/validations";
import {
  EXLY_DASHBOARD__SET_LOADER,
  EXLY_DASHBOARD__SET_BANNER,
  EXLY_DASHBOARD__SET_BANNER_TOAST,
  EXLY_DASHBOARD__SET_LEADS,
  EXLY_DASHBOARD__SET_SUBSCRIPTION_STATS,
  EXLY_DASHBOARD__SET_SELECTED_MONTH,
  EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS,
} from "./actions";

const initialState = {
  loader: true,
  banner: null,
  bannerToast: null,
  leads: {},
  subscription_stats: null,
  selected_filter_month: constants.current_month,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_DASHBOARD__SET_LOADER: {
      return { ...state, loader: payload };
    }
    case EXLY_DASHBOARD__SET_BANNER: {
      return { ...state, banner: payload };
    }
    case EXLY_DASHBOARD__SET_BANNER_TOAST: {
      return { ...state, bannerToast: payload };
    }
    case EXLY_DASHBOARD__SET_LEADS: {
      return { ...state, leads: payload };
    }
    case EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS: {
      return { ...state, subscription_stats: null };
    }
    case EXLY_DASHBOARD__SET_SUBSCRIPTION_STATS: {
      let stat_data;
      if (
        payload &&
        !is_empty(payload) &&
        (payload.total_subscription_renewals > 0 ||
          payload.due_soon_count > 0 ||
          payload.over_due_count > 0 ||
          payload.total_expired_subscriptions > 0)
      ) {
        stat_data = [
          {
            color: "#E5FFF7",
            title: "Renewed Subscription",
            value: "",
            title_color: "#00B77A",
            tooltip: "Clients who have renewed / purchased subscriptions",
          },
          {
            color: "rgba(255, 171, 0, 0.1)",
            title: "Renewal Due Soon",
            value: "",
            title_color: "rgba(255, 171, 0, 1)",
            tooltip:
              "Clients who have purchased a subscription / recurring listing for whom the renewal needs to happen within 3 days",
          },
          {
            color: "rgba(191, 38, 0, 0.1)",
            title: "Renewal Over Due ",
            value: "",
            title_color: "rgba(191, 38, 0, 1)",
            tooltip:
              "Clients who have purchased a subscription / recurring listing who did not renew even after the due date",
          },
          {
            color: "#EEECF9",
            title: "Renewal Success Rate",
            value: "",
            title_color: "#4A3AB1",
            tooltip:
              "% of clients who have renewed a subscription / recurring listing out of all who initially purchased it",
          },
        ];
        stat_data[0].value = payload.total_subscription_renewals;
        stat_data[1].value = payload.due_soon_count;
        stat_data[2].value = payload.over_due_count;
        stat_data[3].value = `${
          payload.total_subscription_renewals === 0
            ? 0
            : roundOff(
                (payload.total_subscription_renewals /
                  (payload.total_subscription_renewals +
                    payload.due_soon_count +
                    payload.over_due_count)) *
                  100,
                2
              )
        }%`;
      }
      return { ...state, subscription_stats: stat_data };
    }
    case EXLY_DASHBOARD__SET_SELECTED_MONTH: {
      return { ...state, selected_filter_month: payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
