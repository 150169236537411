export const EXLY_DASHBOARD__SET_LOADER = "@EXLY_DASHBOARD/SET_LOADER";
export const EXLY_DASHBOARD__REQUEST_BANNER = "@EXLY_DASHBOARD/REQUEST_BANNER";
export const EXLY_DASHBOARD__SET_BANNER = "@EXLY_DASHBOARD/SET_BANNER";
export const EXLY_DASHBOARD__REQUEST_LEADS = "@EXLY_DASHBOARD/REQUEST_LEADS";
export const EXLY_DASHBOARD__SET_LEADS = "@EXLY_DASHBOARD/SET_LEADS";
export const EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS =
  "@EXLY_DASHBOARD/GET_SUBSCRIPTION_STATS";
export const EXLY_DASHBOARD__SET_SUBSCRIPTION_STATS =
  "@EXLY_DASHBOARD/SET_SUBSCRIPTION_STATS";
export const EXLY_DASHBOARD__SET_SELECTED_MONTH =
  "@EXLY_DASHBOARD/SET_SELECTED_MONTH";
export const EXLY_DASHBOARD__REQUEST_BANNER_TOAST =
  "@EXLY_DASHBOARD/REQUEST_BANNER_TOAST";
export const EXLY_DASHBOARD__SET_BANNER_TOAST =
  "@EXLY_DASHBOARD/SET_BANNER_TOAST";
