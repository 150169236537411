import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { api } from "data";
import { matchPath } from "react-router";

const booking_questions_resource_apis = {
  [app_route_ids[app_route_keys.booking_questions]]: ({ pathname }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.booking_questions],
    });
    const listingUuid = matchResults?.params?.listing_uuid;
    return {
      api_end_point: api.questions.get_questions,
      queries: {
        listing_uuid: listingUuid,
      },
    };
  },
};

export default booking_questions_resource_apis;
