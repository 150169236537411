import { app_route_ids, app_route_keys } from "constants/urlPaths";
import email_drip_api from "./EmailDrip.api";
import { EMAIL_DRIP_STATUS } from "./EmailDrip.constants";
import { matchPath } from "react-router";

const email_drip_resource_apis = {
  [app_route_ids[app_route_keys.active_drip]]: {
    api_end_point: email_drip_api.get_email_drip_list,
    queries: {
      status: EMAIL_DRIP_STATUS.ACTIVE,
    },
  },
  [app_route_ids[app_route_keys.draft_drip]]: {
    api_end_point: email_drip_api.get_email_drip_list,
    queries: {
      status: EMAIL_DRIP_STATUS.DRAFT,
    },
  },
  [app_route_ids[app_route_keys.paused_drip]]: {
    api_end_point: email_drip_api.get_email_drip_list,
    queries: {
      status: EMAIL_DRIP_STATUS.PAUSED,
    },
  },
  [app_route_ids[app_route_keys.inactive_drip]]: {
    api_end_point: email_drip_api.get_email_drip_list,
    queries: {
      status: EMAIL_DRIP_STATUS.INACTIVE,
    },
  },
  [app_route_ids[app_route_keys.email_drip_analytics]]: ({ pathname }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.email_drip_analytics],
    });

    return {
      api_end_point: email_drip_api.get_drip_email_list,
      queries: {
        drip_uid: matchResults?.params?.dripUuid,
      },
    };
  },
};

export default email_drip_resource_apis;
