import React from "react";
import { ReactComponent as CodeIcon } from "assets/vectors/code.svg";
import { SALES_PAGE_STATUS } from "ui/pages/SalesPages/constants";
import { OFFERING_STATUSES } from "constants/schedule";
import styles from "./Style.module.css";
import {
  getAppliedTitles,
  getCodePositions,
  MobileHeader,
} from "./components/TableFields/TableFields";
import { COLUMN_KEYS } from "features/CustomCode/CustomCode.constants";
import { StatusField } from "features/CustomCode/modules/StatusField";

export const customCodeLabels = {
  title: "Custom code",
  description: "Add custom code snippets to the head or body of your site.",
  addButton: "Add Custom code",
  save: "Save code",
  emptyOfferingText:
    "You have no live offerings currently, create an offering to Create Add custom code",
};

export const custom_code_form_field_names = {
  name: "name",
  code_snippet: "code_snippet",
  target: "target",
  applicable_pages: "applicable_pages",
  code_position: "code_position",
  listings: "metadata.listings",
  sales_pages: "metadata.sales_pages",
  thankyou_pages: "metadata.thankyou_pages",
};

export const getTableConfig = ({ onEdit, isDesktop }) => [
  {
    field: "avatar",
    headerName: " ",
    valueFormatter: () => <CodeIcon className={styles.code_icon} />,
    width: "40px",
    hidden: true,
  },
  {
    field: "name",
    headerName: "Name",
    ellipsis: true,
    maxCharCount: 40,
    hidden: true,
  },
  {
    field: "mobile_header",
    headerName: "not required jut put for unique keys in component",
    renderColumn: (record) => <MobileHeader record={record} onEdit={onEdit} />,
    hiddenDesktopColumn: true,
  },
  {
    field: "metadata.selected_listings",
    headerName: "Applied on",
    valueFormatter: getAppliedTitles,
    columnClassName: styles.applied_column,
    ellipsis: true,
    maxCharCount: 100,
  },
  {
    field: "code_position",
    headerName: "Place code in",
    valueFormatter: getCodePositions,
    ellipsis: true,
    maxCharCount: 40,
    hidden: true,
  },
  {
    field: COLUMN_KEYS.status.key,
    headerName: COLUMN_KEYS.status.label,
    columnClassName: isDesktop ? "" : "d-flex",
    valueFormatter: (record) => <StatusField record={record} />,
  },
];

export const custom_code_target = {
  HOME: 1,
  ALL_PAGES_OF_ALL_OFFERINGS: 2,
  BOTH: 3,
  SPECIFIC_OFFERING_PAGES: 4,
};

export const offering_specific_pages = {
  CHECKOUT_PAGE: 1,
  SOLD_OUT_PAGE: 2,
  SALES_PAGES: 3,
  THANKYOU_PAGE_PURCHASE: 4,
  THANKYOU_PAGE_OPTIN: 5,
};

export const entity_change_state = {
  NO_CHANGE: 0,
  UPDATED: 1,
  NEW_ADDED: 2,
  DELETED: 3,
};

export const target_dropdown_options = [
  {
    id: custom_code_target.HOME,
    label: "Home page",
    value: custom_code_target.HOME,
  },
  {
    id: custom_code_target.ALL_PAGES_OF_ALL_OFFERINGS,
    label: "All pages of all offerings",
    value: custom_code_target.ALL_PAGES_OF_ALL_OFFERINGS,
  },
  {
    id: custom_code_target.SPECIFIC_OFFERING_PAGES,
    label: "Specific pages for specific offerings",
    value: custom_code_target.SPECIFIC_OFFERING_PAGES,
  },
];

export const offering_specific_pages_options = [
  {
    id: offering_specific_pages.CHECKOUT_PAGE,
    label: "Offering page",
    value: offering_specific_pages.CHECKOUT_PAGE,
  },
  {
    id: offering_specific_pages.SOLD_OUT_PAGE,
    label: "Waitlist page",
    value: offering_specific_pages.SOLD_OUT_PAGE,
  },
  {
    id: offering_specific_pages.SALES_PAGES,
    label: "Sales page(s)",
    value: offering_specific_pages.SALES_PAGES,
  },
  {
    id: offering_specific_pages.THANKYOU_PAGE_PURCHASE,
    label: "Thank you page (for purchases)",
    value: offering_specific_pages.THANKYOU_PAGE_PURCHASE,
  },
  {
    id: offering_specific_pages.THANKYOU_PAGE_OPTIN,
    label: "Thank you page (for opt-ins)",
    value: offering_specific_pages.THANKYOU_PAGE_OPTIN,
  },
];

export const custom_code_positions = {
  HEAD: 1,
  BODY_START: 2,
  BODY_END: 3,
  CONTENT_START: 4,
  CONTENT_END: 5,
};

export const custom_code_position_titles = {
  [custom_code_positions.HEAD]: "Head",
  [custom_code_positions.BODY_START]: "Body - start",
  [custom_code_positions.BODY_END]: "Body - end",
  [custom_code_positions.CONTENT_START]: "Content - start",
  [custom_code_positions.CONTENT_END]: "Content - end",
};

export const code_postion_options = [
  {
    id: custom_code_positions.HEAD,
    label: custom_code_position_titles[custom_code_positions.HEAD],
    value: custom_code_positions.HEAD,
  },
  {
    id: custom_code_positions.BODY_START,
    label: custom_code_position_titles[custom_code_positions.BODY_START],
    value: custom_code_positions.BODY_START,
  },
  {
    id: custom_code_positions.BODY_END,
    label: custom_code_position_titles[custom_code_positions.BODY_END],
    value: custom_code_positions.BODY_END,
  },
  {
    id: custom_code_positions.CONTENT_START,
    label: custom_code_position_titles[custom_code_positions.CONTENT_START],
    value: custom_code_positions.CONTENT_START,
  },
  {
    id: custom_code_positions.CONTENT_END,
    label: custom_code_position_titles[custom_code_positions.CONTENT_END],
    value: custom_code_positions.CONTENT_END,
  },
];

export const salesPgStatusToShow = [
  SALES_PAGE_STATUS.LIVE,
  SALES_PAGE_STATUS.SOLD_OUT,
];

export const offeringStatusToShow = [
  OFFERING_STATUSES.live.value,
  OFFERING_STATUSES.soldout.value,
];

export const applied_on_title_config = {
  separator: ", ",
  SPECIFIC_OFFERING_PAGES_TITLE: "Specific pages",
};
