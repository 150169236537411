import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { matchPath } from "react-router";
import { getRAUrlParams } from "utils/urlUtils";
import { manage_branded_community_apis } from "../../../../constants/ManageBrandedCommunity.apis";

const member_purchases_resource_apis = {
  [app_route_ids[app_route_keys.manage_branded_community_members_purchases]]: ({
    pathname,
  }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.manage_branded_community_home],
    });
    const memberUuid = getRAUrlParams().get("member_uuid");
    return {
      api_end_point:
        manage_branded_community_apis.get_community_members_purchases,
      queries: {
        community_listing_id: matchResults?.params?.branded_community_uuid,
        member_username: memberUuid,
      },
    };
  },
};

export default member_purchases_resource_apis;
