export const LEADERBOARD_TABS = {
  SCOREBOARD: 1,
  SETTINGS: 2,
};

export const LEADERBOARD_TAB_OPTIONS = [
  {
    label: "Score board",
    value: LEADERBOARD_TABS.SCOREBOARD,
  },
  {
    label: "Settings",
    value: LEADERBOARD_TABS.SETTINGS,
  },
];

export const rankingDataKeys = {
  bookingUuid: "booking_uuid",
  customerName: "customer_name",
  rank: "rank",
  totalPoints: "total_points",
  courseProgress: "course_progress",
  timeTaken: "time_taken",
  totalExamScore: "total_exam_score",
  isSelf: "is_self",
};

export const LEADERBOARD_LEARN_MORE_HREFS = {
  LEADERBOARD:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392231-how-to-create-a-leaderboard-for-your-course-",
};
