import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { matchPath } from "react-router";
import { recordedContentAPI } from "schedule-v2/RecordedContent/recordedContent.api";
import { rankingDataKeys } from "ui/pages/Leaderboard/leaderboard.data";
import { getRAUrlParams } from "utils/urlUtils";

const leaderboard_scoring_breakdown_resource_apis = {
  [app_route_ids[app_route_keys.leaderboard_breakdown_modal]]: ({
    pathname,
  }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.leaderboard],
    });
    const listingUuid = matchResults?.params?.listing_uuid;
    const bookingUuid = getRAUrlParams().get(rankingDataKeys.bookingUuid);

    return {
      api_end_point: recordedContentAPI.report("exams"),
      queries: {
        booking_uid: bookingUuid,
        listing_uid: listingUuid,
      },
    };
  },
};

export default leaderboard_scoring_breakdown_resource_apis;
