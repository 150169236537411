import { is_empty } from "utils/validations";
import {
  EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_BONUS_OFFERINGS,
  EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_ONBOARDING,
  EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_COMMUNITY_DATA,
} from "./actions";

const initialState = {
  communities: {},
  selectedCommunityUuid: "",
  onboarding: {
    isOnboarded: true,
    onboardingStep: null,
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_COMMUNITY_DATA: {
      if (is_empty(payload) || is_empty(payload?.data)) {
        return state;
      }
      return {
        ...state,
        communities: {
          ...state.communities,
          [payload.uuid]: Object.assign(
            { ...state.communities[payload.uuid] },
            payload.data
          ),
        },
        selectedCommunityUuid: payload.uuid,
      };
    }

    case EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_BONUS_OFFERINGS: {
      if (is_empty(payload) || is_empty(payload?.data)) {
        return state;
      }
      return {
        ...state,
        communities: {
          ...state.communities,
          [payload.uuid]: {
            ...state.communities[payload.uuid],
            offerings: payload.data,
          },
        },
        selectedCommunityUuid: payload.uuid,
      };
    }

    case EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_ONBOARDING: {
      return {
        ...state,
        onboarding: Object.assign(state.onboarding, payload),
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
