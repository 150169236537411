import { appendUrlParams } from "utils/urlUtils";

export const leaderboard_api = {
  get_customers: "host/course/leaderboard/customers",
  update_leaderboard_config: "host/course/leaderboard/config/update",
  get_leaderboard_config: ({ listingUuid }) =>
    appendUrlParams("host/course/leaderboard/config", {
      listing_uuid: listingUuid,
    }),
};
