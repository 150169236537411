import React from "react";
import classnames from "classnames";
import { Switch, MobilePopover } from "@my-scoot/component-library-legacy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

import { ReactComponent as CodeIcon } from "assets/vectors/code.svg";
import useStyles from "./TableFields.styles";
import { updateCustomCode } from "../../Codes.api.helpers";
import { is_empty } from "utils/validations";
import {
  custom_code_position_titles,
  target_dropdown_options,
  custom_code_target,
  applied_on_title_config,
} from "../../Codes.config";
import { custom_code_activation_status } from "features/CustomCode/CustomCode.constants";

export const getAppliedTitles = (record) => {
  const { target, listings_mapping } = record;
  const targetData = target_dropdown_options.find(
    (option) => option.value === target && option.value
  );
  let dynamicTitle = "";
  const separator = applied_on_title_config.separator;
  switch (targetData.value) {
    case custom_code_target.HOME:
      return targetData.label;
    case custom_code_target.ALL_PAGES_OF_ALL_OFFERINGS:
      return targetData.label;
    case custom_code_target.SPECIFIC_OFFERING_PAGES:
      dynamicTitle = applied_on_title_config.SPECIFIC_OFFERING_PAGES_TITLE;
      if (!is_empty(listings_mapping)) {
        dynamicTitle = dynamicTitle.concat(" for ");
        listings_mapping.forEach((val) => {
          dynamicTitle = dynamicTitle.concat(val.title + separator);
        });
        dynamicTitle = dynamicTitle.substring(
          0,
          dynamicTitle.length - separator.length
        );
      }
      return dynamicTitle;
    default:
      return dynamicTitle;
  }
};

export const getCodePositions = (record) => {
  const { code_position } = record;
  const titles = code_position
    .sort((a, b) => a - b)
    .map((value) => custom_code_position_titles[value]);
  return titles.join(", ");
};

export const SwitchField = ({ record }) => {
  const classes = useStyles();
  const isActive = custom_code_activation_status.ACTIVE === record.status;

  const updateStatus = () => {
    updateCustomCode({
      code_uuid: record.uuid,
      updated_values: {
        status: isActive
          ? custom_code_activation_status.ARCHIVE
          : custom_code_activation_status.ACTIVE,
      },
    })
      .then(() => window.location.reload())
      .catch(console.log);
  };

  return (
    <Switch
      rootWrapperClassName={isActive && classes.switchRootWrapperClassName}
      checked={isActive}
      onChange={updateStatus}
      color="success"
      size="large"
    />
  );
};

export const MobileHeader = ({ record }) => {
  const classes = useStyles();

  return (
    <div className={classnames("d-flex align-items-center", classes.gap_12)}>
      <CodeIcon className={classes.codeIcon} />
      <div className={classnames("ellipsis", classes.name)}>{record.name}</div>
    </div>
  );
};

export const MobileActions = ({ open, onClose, actions }) => {
  const classes = useStyles();

  return (
    <MobilePopover open={open} onClose={onClose}>
      {actions.map(({ label, icon, onClick }) => (
        <div className={classes.mobileBtn} key={label} onClick={onClick}>
          {icon}
          <div>{label}</div>
        </div>
      ))}
    </MobilePopover>
  );
};

export const DesktopActions = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.desktopActions}>
      <IconButton className={classes.iconButton} onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};
