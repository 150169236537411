import { put, call, select, takeLatest } from "redux-saga/effects";
import {
  EXLY_COMMUNITY__BRANDED_COMMUNITY__FETCH_BONUS_OFFERINGS,
  EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_BONUS_OFFERINGS,
} from "./actions";
import { is_empty } from "utils/validations";
import { dataProvider } from "data";
import { manage_branded_community_apis } from "../constants/ManageBrandedCommunity.apis";

function* fetchBonusOfferings({
  communityUuid,
  successCallback,
  errorCallback,
  force_update,
}) {
  try {
    if (is_empty(communityUuid)) return;
    const bonus_offerings = yield select(
      (state) => state.community.communities[communityUuid]?.bonus_offerings
    );
    if (is_empty(bonus_offerings) || force_update) {
      const response = yield call(
        dataProvider.custom_request,
        manage_branded_community_apis.get_bonus_offerings,
        "GET",
        {
          community_listing_id: communityUuid,
        }
      );
      if (response?.status === 200) {
        yield put({
          type: EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_BONUS_OFFERINGS,
          payload: { data: response.data, uuid: communityUuid },
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${manage_branded_community_apis.get_bonus_offerings} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_BONUS_OFFERINGS,
        payload: bonus_offerings,
      });
    }
  } catch (error) {
    console.log("error", error);
    yield put({
      type: EXLY_COMMUNITY__BRANDED_COMMUNITY__SET_BONUS_OFFERINGS,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* communitySaga() {
  yield takeLatest(
    EXLY_COMMUNITY__BRANDED_COMMUNITY__FETCH_BONUS_OFFERINGS,
    fetchBonusOfferings
  );
}

export default communitySaga;
