export const constants_media = {
  video_type: 1,
};

export const MEDIA_LIBRARY_LEARN_MORE_HREFS = {
  MEDIA_LIBRARY:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392250-media-library-tool-on-exly",
};

export const MEDIA_LIBRARY_LIST_VIEW_TYPES = {
  ICON: 1,
  LIST: 2,
};
