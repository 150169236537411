import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    codeIcon: {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      flexBasis: "32px",
    },
    gap_12: {
      gap: "12px",
    },
    name: {
      maxWidth: "50%",
      width: "50%",
    },
    actionsWrap: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "16px",
    },
    iconButton: {
      padding: 0,
      transform: "rotate(90deg)",
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
    switchRootWrapperClassName: {
      borderColor: theme.palette.primary.main,
    },
    mobilePaper: {
      paddingBottom: "62px",
    },
    mobileBtn: {
      color: theme.palette.basic.black,
      fontSize: "16px",
      lineHeight: "19px",
      "& svg": {
        fill: theme.palette.basic.black,
      },
      height: "53px",
      boxSizing: "border-box",
      borderBottom: `1px solid ${theme.palette.secondary.shade_100}`,
      display: "flex",
      alignItems: "center",
      gap: "16px",
      justifyContent: "flex-start",
      cursor: "pointer",
      padding: "0 16px",
    },
    desktopActions: {
      display: "flex",
      alignItems: "center",
      gap: "34px",
    },
    popover_paper: {
      boxShadow: "0px 1px 4px rgba(14, 13, 12, 0.14)",
      borderRadius: "5px",
      padding: "10px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    menu_link: {
      width: "100%",
      fontSize: "16px",
      padding: "6px 16px",
      color: "#272522",
      cursor: "pointer",
      "&:hover": {
        background: "#E7E6E4",
      },
    },
    "@media (max-width:780px)": {},
  }),
  { name: "CreatorTool" }
);

export default useStyles;
