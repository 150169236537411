import { api, dataProvider } from "data";
import { sales_pages_apis } from "../SalesPages/API";
import { apiMethods } from "data/api.constants";
import { OFFERING_STATUSES } from "constants/schedule";
import { SALES_PAGE_STATUS } from "../SalesPages/constants";
import { is_empty } from "utils/validations";
import { THANKYOU_PAGE_API } from "../ThankYou/api/thankyou.api.constants";

export const createCustomCode = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.create_custom_code, apiMethods.POST, payload)
      .then(resolve)
      .catch(reject);
  });

export const updateCustomCode = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.update_custom_code, apiMethods.POST, payload)
      .then(resolve)
      .catch(reject);
  });

export const fetchActiveOfferings = (pageNumber, payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.fetch_offerings, apiMethods.GET, {
        page: pageNumber,
        status: 1,
        ...payload,
      })
      .then(({ data, status }) => {
        if (status !== 200) throw new Error(`Error fetching active listings`);
        resolve(data);
      })
      .catch(reject);
  });

export const fetchStatusBasedOfferings = ({
  pageNumber = "all",
  payload,
  status = OFFERING_STATUSES.live.value,
}) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(api.fetch_offerings, apiMethods.GET, {
        page: pageNumber,
        status,
        ...payload,
      })
      .then(({ data, status }) => {
        if (status !== 200) throw new Error(`Error fetching active listings`);
        resolve(data);
      })
      .catch(reject);
  });

export const fetchStatusAndListingBasedSalesPgs = ({
  pageNumber = "all",
  payload,
  status = SALES_PAGE_STATUS.LIVE,
  listings = "",
}) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(sales_pages_apis.get_sales_pages_list, apiMethods.GET, {
        page: pageNumber,
        status,
        ...(!is_empty(listings) && { listing_uuid: listings }),
        ...payload,
      })
      .then(({ data, status }) => {
        if (status !== 200) throw new Error(`Error fetching active listings`);
        resolve(data);
      })
      .catch(reject);
  });

export const fetchListingBasedThankyouPgs = ({
  pageNumber = "all",
  payload,
  listings = "",
}) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(THANKYOU_PAGE_API.get_pages_list, apiMethods.GET, {
        page: pageNumber,
        ...(!is_empty(listings) && { listing_uuid: listings }),
        ...payload,
      })
      .then(({ data, status }) => {
        if (status !== 200) throw new Error(`Error fetching active listings`);
        resolve(data);
      })
      .catch(reject);
  });
