export const EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE =
  "@EXLY_FEEDBACKS/PUBLISHED_REVIEWS/UPDATE_STATE";
export const EXLY_FEEDBACKS__PUBLISHED_REVIEWS__RESET_STATE =
  "@EXLY_FEEDBACKS/PUBLISHED_REVIEWS/RESET_STATE";
export const EXLY_FEEDBACKS__PUBLISHED_REVIEWS__EDIT_REVIEW =
  "@EXLY_FEEDBACKS/PUBLISHED_REVIEWS/EDIT_REVIEW";
export const EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DUPLICATE_REVIEW =
  "@EXLY_FEEDBACKS/PUBLISHED_REVIEWS/DUPLICATE_REVIEW";
export const EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DELETE_REVIEW =
  "@EXLY_FEEDBACKS/PUBLISHED_REVIEWS/DELETE_REVIEW";
export const EXLY_FEEDBACKS__PUBLISHED_REVIEWS__OPEN_REVIEW_MODAL =
  "@EXLY_FEEDBACKS/PUBLISHED_REVIEWS/OPEN_REVIEW_MODAL";
