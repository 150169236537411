export const EXLY_ACCOUNT__GET_FEATURE_CREDITS =
  "@EXLY_ACCOUNT/GET_FEATURE_CREDITS";
export const EXLY_ACCOUNT__SET_FEATURE_CREDITS =
  "@EXLY_ACCOUNT/SET_FEATURE_CREDITS";

export const EXLY_ACCOUNT__GET_CREATOR_ACTIVE_PLAN =
  "@EXLY_ACCOUNT/GET_CREATOR_ACTIVE_PLAN";
export const EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN =
  "@EXLY_ACCOUNT/SET_CREATOR_ACTIVE_PLAN";
export const EXLY_ACCOUNT__GET_CART = "@EXLY_ACCOUNT/GET_CART";
export const EXLY_ACCOUNT__SET_CART = "@EXLY_ACCOUNT/SET_CART";
export const EXLY_ACCOUNT__GET_LEDGER_TRIGGERS =
  "@EXLY_ACCOUNT/GET_LEDGER_TRIGGERS";
export const EXLY_ACCOUNT__SET_LEDGER_TRIGGERS =
  "@EXLY_ACCOUNT/SET_LEDGER_TRIGGERS";
